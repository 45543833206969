@import '../../../styles/css/variables';

.plant-not-alive {
  margin: 1.5rem 0;
}

.plant-not-alive__description {
  color: $green;
  font-weight: bold;
}
