@import 'variables.scss';
@import 'mixin.scss';

#header {
  box-sizing: border-box;
  border-bottom: 1px solid $favorite-plants-border;
}

.header-container {
  @include default-container-width;
  height: $header__height;
  display: flex;
  align-items: center;
  background-color: $white;
  padding: 0 1rem;

  .logo-section {
    display: flex;
    a {
      display: flex;
      text-decoration: none;
      white-space: nowrap;

      img {
        height: 1.45rem;
        width: 6rem;
        margin-top: auto;
        margin-bottom: auto;
      }

      .logo-section__label {
        margin: auto 0 auto 1rem;
        padding: 0 1rem;
        font-size: $font-size-1;
        color: rgba(0, 0, 0, 0.7);
        height: 2.25rem;
        line-height: 2.25rem;
        border-left: 1px solid $dark-grey;
      }
    }
  }

  .header-menu__button {
    @include button;

    @media screen and (min-width: $device-width__break-point) {
      display: none;
    }
  }

  .header-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
  }

  .header-menu__links-nest,
  .header-menu,
  .header-menu__links {
    height: auto;
  }

  .header-menu__links-nest,
  .header-menu__links {
    display: flex;

    .sub-menu-options {
      display: none;
      flex-direction: column;
      position: absolute;
      z-index: 2;
      padding-left: 0;
      top: $header__height;
      margin-top: 0rem;
      margin-bottom: 0rem;

      a {
        text-align: left;
      }
    }
  }

  .header-menu__links-nest {
    .header-menu__link {
      svg {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  .header-menu__links-nest:hover {
    .header-menu__link {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .sub-options:hover {
    ul,
    a {
      display: flex;
      background-color: $black;
      color: $white;
    }
  }

  .header-menu__link {
    color: $black;
    font-size: $font-size-1;
    line-height: 2.5rem;
    text-decoration: none;
    padding: 0.5rem 1rem;
    letter-spacing: 0.5px;
    text-align: center;
    vertical-align: middle;
    border: 0;
    cursor: pointer;
    background-color: transparent;

    &.green {
      color: $green;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $black;
        color: $white;
      }
    }
  }
}

@media screen and (max-width: $device-width__break-point) {
  .header-container {
    .header-menu__links {
      display: none;
      position: fixed;
      top: $header__height;
      left: 0;
      right: 0;
      flex-direction: column;
      text-align: center;
      border-top: 1px solid $dark-grey;
      background-color: #fff;
      z-index: 10;
      //height: calc(100vh - #{$header__height});

      &[data-mobile-menu-open='true'] {
        display: flex;
      }

      .header-menu__links-nest,
      .header-menu__link {
        padding: 0;
        // height: $esri--button__length;
        // line-height: $esri--button__length;
        box-sizing: border-box;
        border-bottom: 1px solid $light-grey;
        justify-content: center;

        &.sub-options {
          flex-wrap: wrap;
          width: 100%;

          .header-menu__link {
            width: 100%;
            text-align: center;

            &.hide-on-mobile {
              display: none;
            }

            a.header-menu__link > svg.chevron-down {
              margin-top: auto;
              margin-bottom: auto;
            }
          }
        }

        &.sub-options:hover {
          ul,
          a {
            display: flex;
            background-color: $white;
            color: $black;
          }
        }
      }

      .header-menu__links-nest {
        border-bottom-width: 0px;

        .header-menu__link > svg {
          display: none;
        }
      }

      .sub-menu-options {
        display: flex;
        position: unset;
        background-color: $white;
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: $mobile-device) {
  .hide-on-desktop {
    display: none;
  }
}
