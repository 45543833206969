@import '../css/variables.scss';
@import '../css/mixin';

.map-layers-button {
  @include esri-button;
}

.filter-container {
  &.map-layers {
    .option-button,
    .sub-header {
      .custom-form-control-label {
        margin: 0;
      }
      &.layers {
        padding: 1.5rem 0 1.5rem 1rem;
      }
    }

    .dropdown {
      ul > li {
        display: flex;
        justify-content: space-between;
        margin: 0 0 0.25rem 0;

        div {
          display: flex;

          img {
            height: 35px;
            width: 35px;
            padding-right: 0.5rem;
          }

          label {
            margin-top: auto;
            margin-bottom: auto;
            color: $dark-grey;
          }
        }

        .MuiFormControlLabel-root {
          margin: 0;
        }
      }
    }
  }
}

.filter-layers__toggle-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.25rem;
  background-color: rgb(0, 0, 0, 0.1);

  .custom-form-control-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 0.25rem 0 0.25rem 0;
  }
}
