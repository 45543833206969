@import './variables.scss';

.error-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  margin: 3rem;

  h2 {
    font-size: 6rem;
    margin: 0 auto;
  }

  h3 {
    font-size: 1.5rem;
  }

  a {
    text-decoration: none;
  }
}

.results-error-container {
  text-align: center;
  padding: 1rem;
  svg {
    margin-top: auto;
    margin-bottom: auto;
  }
  h3 {
    text-align: center;
    color: #333333;
    font-weight: 400;

    span {
      font-style: italic;
      color: #5e5e5e;
    }
  }
}
