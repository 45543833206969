@import './variables.scss';
@import '../css/mixin';

.legend-button {
  @include esri-button;
  width: auto;
  padding: 0 1rem 0 1.25rem;
  font-size: $font-size-2;

  .burger-icon,
  .close-legend,
  .esri-legend__message {
    margin-top: auto;
    margin-bottom: auto;
  }

  .button-icon {
    height: $esri--button__icon-length;
    width: $esri--button__icon-length;

    &.esri-icon-close {
      font-weight: bold;
      font-size: 22px;
      color: #d14444;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .burger-icon {
    background-image: url('../images/LegendHamburger.svg');
    background-repeat: no-repeat;
    background-size: $esri--button__icon-length;
  }

  .legend__button-label {
    padding-left: 1rem;
  }
}

#legend-dropdown-container {
  background-color: #fff;
  height: 210px;
  width: 275px;
  min-width: 230px;
  margin: $esri--button__margin 0 0 0;
  border-radius: 6px;
  box-shadow: $esri--button__box-shadow;
  z-index: 1;

  .filter-container {
    width: 100%;
    height: 260px;

    .sub-header.layers,
    .option-button.layers {
      padding: 0 0 0 1rem;
      min-height: $modal-item--default-height;
    }

    .sub-header.layers {
      display: flex;
      align-items: center;
      box-sizing: border-box;
    }

    .option-button,
    .option-button.layers {
      height: $modal-item--default-height - 10px;
      min-height: $modal-item--default-height - 10px;
    }
  }
}

@media only screen and (max-width: $mobile-device) {
  .legend-button {
    &.custom-width-on-mobile {
      width: 3rem;
    }
  }
  .legend-button > .button-label {
    &.hide-on-mobile {
      display: none;
    }
  }
}
