@import '../../../styles/css/variables.scss';

.TourOptionsItem {
  display: flex;
  width: 100%;
  min-height: $SearchPlant__button--length;
  align-items: center;
  margin-bottom: 0.5rem;

  [name='checkbox'] {
    height: 20px;
    width: 20px;
    margin: auto 5px auto 0;
  }

  .curated-tour-button {
    flex: 1;
    border: none;
    background-color: unset;
    color: $black;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    margin: 0;
    padding: 0 0.25rem;
    text-align: left;
  }

  .TourOptionsItem__description-toggle {
    width: 26px;
    height: 26px;
    padding: 0;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
