@import '../../../../styles/css/variables';

.species-commonname {
  display: flex;

  .species {
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0.5px;
    text-decoration: none;
    margin: $SearchPlantItem__margin;
    color: $green;
    line-height: 1.5;

    a {
      color: $green;
    }
  }

  .species:hover {
    text-decoration: underline;
  }
}

.plant-result-icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  padding-right: 0.25rem;
}
