@import '../css/variables.scss';
@import '../css/mixin';

.filter-container {
  display: flex;
  flex-direction: column;
  height: 29.7rem;
  overflow-y: auto;
  border-radius: 5px;
  background-color: $white;

  .sub-header {
    display: flex;
    border-bottom: 1px solid $favorite-plants-border;
    padding: 1.5rem 1rem 1.5rem 1rem;
    justify-content: space-between;

    span,
    .clear {
      margin-top: auto;
      margin-bottom: auto;
    }

    span {
      font-size: 20px;
    }

    .clear {
      height: 1.25rem;
      color: $dark-grey;
      font-size: 16px;
      text-decoration: underline;
      background-color: $white;
      border: none;
    }
  }

  .option-button {
    cursor: pointer;
    height: $modal-item--default-height;
    align-items: center;
    display: flex;
    padding: 0 1rem;
    border-bottom: 1px solid $favorite-plants-border;
    justify-content: space-between;
    box-sizing: border-box;

    .label-wrapper {
      display: flex;
      align-items: center;
      color: $black;
      font-size: 16px;
      font-weight: 600;

      img {
        margin-right: 0.25rem;
      }

      .label {
        padding-left: 0.25rem;
      }

      .option-icon__container {
        height: $modal-item--default-height;
        display: flex;
        align-items: center;
      }

      .active-checkbox-num {
        background-color: $green;
        border-radius: 50%;
        color: $white;
        margin-left: 0.5rem;
        text-align: center;
        font-size: 14px;
        width: 1.25rem;
        height: 1.25rem;
        padding: 0.2rem;
      }
    }

    .chevron.up::before {
      transform: rotate(-45deg);
    }

    .chevron.down::before {
      transform: rotate(135deg);
    }

    .chevron::before {
      border-style: solid;
      border-width: 0.1rem 0.1rem 0 0;
      content: '';
      display: inline-block;
      height: 0.45rem;
      position: relative;
      width: 0.45rem;
      color: grey;
    }
  }

  .dropdown {
    position: relative;
    background-color: #f3f3f3;

    ul {
      margin: 0 auto;
      padding: 0.5rem 0 0.25rem 1rem;
      list-style-type: none;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        min-height: 35px;
        font-size: 16px;
        padding-bottom: 0.25rem;

        .custom-checkbox-container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: $checkbox-margin;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          .checkmark {
            position: absolute;
            top: calc(#{$checkbox-margin} / 2);
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #ccc;
          }

          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          input:checked ~ .checkmark:after {
            display: block;
          }

          .checkmark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          label {
            line-height: 35px;
            font-size: 16px;
          }
        }

        .custom-checkbox-container input:checked ~ .checkmark {
          background-color: black;
        }

        .default-checkbox {
          margin: 0 0.5rem 0 0;
        }
      }
    }
  }
}

.filter-button {
  @include esri-button;

  @media screen and (max-height: 768px) {
    bottom: 12rem;
  }
}
