@import '../../../styles/css/variables';

$legend-item--height: 25px;
$color--checked: #ccc;
$color--flowering: #aa0156;
$color--fruiting: #715908;
$year-cell--width: 40px;

.phenology-chart {
}

.phenology-chart__title {
  color: #000;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin: 2rem 0 0 0;
  font-size: $font-size-2;
}

.phenology-chart__select-year {
  height: $legend-item--height;
  padding: 0 0.25rem;
  box-sizing: border-box;
}

.phenology-chart__select-year--title,
.phenology-chart__legend-flowering--title,
.phenology-chart__legend-fruiting--title,
.phenology-chart__legend-checked--title {
  font-weight: 600;
  font-size: 14px;
}

.phenology-chart__select-year--title {
  margin-top: 0;
}

.phenology-chart__legend {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
}

.phenology-chart__legend-labels {
  display: flex;
  padding: 1rem 0 0.25rem 0;
}

.phenology-chart__legend-flowering--title,
.phenology-chart__legend-fruiting--title,
.phenology-chart__legend-checked--title {
  line-height: 20px;
}

.phenology-chart__legend-flowering--color,
.phenology-chart__legend-fruiting--color,
.phenology-chart__legend-checked--color {
  margin: 0 0.75rem;
  display: block;
  box-sizing: border-box;
  height: 20px;
  min-width: 35px;
  border: 1px solid #000000;
}

.phenology-chart__legend-flowering--color {
  background-color: $color--flowering;
}

.phenology-chart__legend-fruiting--color {
  background-color: $color--fruiting;
}

.phenology-chart__legend-checked--color {
  background-color: $color--checked;
}

// TABLE
.phenology-chart__table-container {
  overflow-x: auto;
  margin-bottom: 1rem;

  @media (max-width: $mobile-device) {
    padding-bottom: 0.45rem;
    background-repeat: no-repeat;
    background-size: $table-shadow--1 100%, $table-shadow--1 100%, $table-shadow--2 100%, $table-shadow--2 100%;
    background-attachment: local, local, scroll, scroll;
    background-image: linear-gradient(to right, #fff, #fff), linear-gradient(to right, #fff, #fff),
      linear-gradient(to right, $table-shadow--color-dark, $table-shadow--color-transparent),
      linear-gradient(to left, $table-shadow--color-dark, $table-shadow--color-transparent);
    background-position: left center, right center, left center, right center;
  }
}

.phenology-chart__table {
  border-collapse: collapse;

  .phenology-chart__table-header {
  }

  .phenology-chart__table-header--month {
    color: #fff;
    background-color: #000;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    text-align: center;
    text-transform: uppercase;
  }

  .table-cell {
    border: 1px solid #999;
  }

  .table-cell-label,
  .cell-year {
    width: $year-cell--width;
    font-size: 12px;
    font-weight: 600;
  }

  .cell-short,
  .cell-long {
    text-align: center;
    box-sizing: border-box;
  }

  .cell-short {
    height: 10px;
  }

  .cell-long {
    height: 30px;
  }

  .type_flowering {
    &[data-plants-checked='1'] {
      background-color: lighten($color--flowering, 60%);
    }

    &[data-plants-checked='2'] {
      background-color: lighten($color--flowering, 40%);
    }

    &[data-plants-checked='3'] {
      background-color: lighten($color--flowering, 20%);
    }

    &[data-plants-checked='4'] {
      background-color: $color--flowering;
    }
  }

  .type_fruiting {
    &[data-plants-checked='1'] {
      background-color: lighten($color--fruiting, 60%);
    }

    &[data-plants-checked='2'] {
      background-color: lighten($color--fruiting, 40%);
    }

    &[data-plants-checked='3'] {
      background-color: lighten($color--fruiting, 20%);
    }

    &[data-plants-checked='4'] {
      background-color: $color--fruiting;
    }
  }

  .table-header--year {
    width: $year-cell--width;
    border: none;
    background-color: transparent;
  }

  .cell-year {
    text-align: center;
    border: 1px solid transparent;
    border-right: 1px solid #999;
  }

  [data-week-match='true'] {
    background-color: $color--checked;
  }

  [data-quarter-match='true'] {
    background-color: $color--checked;
  }

  .phenology-chart__table-row--flowering {
    [data-week-match-flowering='true'] {
      background-color: $color--flowering;
    }
  }

  .phenology-chart__table-row--fruiting {
    [data-week-match-fruiting='true'] {
      background-color: $color--fruiting;
    }
  }

  .table-row__flowering--title,
  .table-row__fruiting--title {
    font-weight: 600;
    padding: 0.75rem 0 0.25rem 0;

    .table-row__flowering--plant,
    .table-row__fruiting--plant {
      color: $color--light-green;
      line-height: 1.35;
    }
  }

  .phenology-chart__table-header--cell {
    background-color: #000;
    border: 1px solid #000;

    .phenology-chart__table-header {
      display: flex;
    }
  }
}

@media (hover: hover) {
  #PhenologyChartLegend {
    display: none;
  }
}

@media (hover: none) {
  .hide-on-mobile,
  .table-header--year,
  .table-row:not([data-selected-year='true']) {
    display: none;
  }
}
