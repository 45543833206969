@import '../../styles/css/variables';
@import '../../styles/css/mixin';

.IconReset__container {
  display: flex;
  align-items: center;
  height: $SearchPlant__search-bar--height;
  line-height: $SearchPlant__button--length;

  .IconReset__desktop,
  .IconReset__mobile {
    margin: 0 0 0 0.35rem;
  }

  .IconReset__desktop {
    color: $green;
    font-size: $font-size-1;
    font-weight: 600;

    @media (hover: none) {
      display: none;
    }
  }

  .IconReset__mobile.esri-icon-close-circled {
    color: $dark-grey;
    font-size: 1.75rem;

    @media (hover: hover) {
      display: none;
    }
  }
}
