/* COLORS
============================================= */
$white: #ffffff;
$black: #000000;
$chevron-grey: #444444;
$dark-dark-grey: #333333;
$dark-grey: #5e5e5e;
$medium-dark-grey: rgb(117, 117, 117);
$medium-grey: #b8b8b8;
$light-grey: #b6b6b6;
$tours-options-grey: #f3f3f3;
$flowering-color: #ffa3cd;

$green: #2ec16b;
$color--light-green: lighten($green, 10%);

$color--red-danger: #d14444;

$favorite-plants-header-height: 3rem;
$favorite-plants-border: #dcdcdc;

$selected-item__background: rgb(0, 0, 0, 0.075);

/* FEATURE DIMENSIONS
============================================= */

/* FONTS
============================================= */
$nybg-gothic__font-family: 'NY Botanical Gothic Extrabold Regular';
$nybg-gt__font-family: 'GT Super Text Regular';

// Font Family
$nybg-title__font-family: $nybg-gothic__font-family, 'Times New Roman', Times, serif;
$default__font-family__normal: $nybg-gt__font-family, 'GT Super Text Regular', sans-serif;

// Font Size
$font-increment: 3px;
$font-size-1: 16px;
$font-size-2: $font-size-1 + (1 * $font-increment);
$font-size-3: $font-size-1 + (2 * $font-increment);
$font-size-4: $font-size-1 + (3 * $font-increment);
$font-size-5: $font-size-1 + (4 * $font-increment);
$font-size-6: $font-size-1 + (5 * $font-increment);
$font-size-7: $font-size-1 + (6 * $font-increment);
$font-size-8: $font-size-1 + (7 * $font-increment);
$font-size-9: $font-size-1 + (8 * $font-increment);

/* CONTENT
============================================= */
$content__max-width: 1200px;

/* MEDIA QUERY, DEVICE WIDTH
============================================= */
$device-width__break-point: 960px;
$tablet-device: 768px;
$mobile-device: 475px;
$iphone5: 320px;

/* MISC GLOBAL CLASSES
============================================= */

/* HEADER
============================================= */
$header__height: 60px;
$card-height: 10rem;

/* HEADER
============================================= */
.small-button {
  display: flex;
  position: absolute;
  justify-content: space-evenly;
  border-radius: 50%;
  margin: 0.25rem 1rem 1rem 1rem;
  text-align: center;
  border: none;
  bottom: calc(100% - 35rem);
  left: 1rem;
  height: 35px;
  width: 35px;
  background-color: $white;
  box-shadow: 0 0 4px 0 rgba(94, 94, 94, 0.2);
}

/* MODAL
============================================= */
$modal-item--default-height: 60px;
$modal-button__font-size: 14px;

/* SPINNER
============================================= */
$loader-height-width: 40px;

/* ESRI
============================================= */
$esri--button__length: 44px;
$esri--button__margin: 10px;
$esri--button__box-shadow: 0 0 10px 2px rgba(94, 94, 94, 0.2);
$esri--button__icon-length: 22px;

/* SEARCH PLANT
============================================= */
$SearchPlant__container--height: 368px;
$SearchPlant__search-bar--height: 50px;
$SearchPlant__search-bar--mobile-height: 122px;
$SearchPlant__preview-details--height-desktop: 145px;
$SearchPlant__preview-details--height-mobile: 100px;
$SearchPlant__spacing: 15px;
$SearchPlant__button--length: 35px;

$SearchPlantItem__margin: 0 0.65rem 0 0;

$SearchPlantsCardHeader__margin: 10px;
$SearchPlantAlphabet--height: 50px;
$SearchPlantAlphabet--height-mobile: 69px;

$SearchPlantResults__container--desktop: $SearchPlant__container--height - ($SearchPlantAlphabet--height * 2);
$SearchPlantResults__container--mobile: $SearchPlant__container--height - ($SearchPlantAlphabet--height * 3);

$SearchPlantResults--garden__container--desktop: $SearchPlant__container--height - ($SearchPlantAlphabet--height * 1);
$SearchPlantResults--garden__container--mobile: $SearchPlant__container--height - ($SearchPlantAlphabet--height * 2);

$search-buttons-width-desktop: 9rem;
$search-buttons-width-mobile: 7rem;

/* PLANT RESULTS - FULL PAGE
============================================= */
$table-width: 23.75rem;
$table-min-width: 18rem;

/* ESRI MAP
============================================= */
$map__default-margin: 15px;
$map__expanded-bottom-offsett: 0 - $map__default-margin;
$esri-ui--offset: $SearchPlant__search-bar--height + $map__default-margin;

/* FILTER WIDGET
============================================= */
$checkbox-margin: 12px;

/* IMAGES
============================================= */
$arrow-up: "data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23.7246 17.5881C23.0846 16.9326 16.3482 10.5252 16.3482 10.5252C16.0064 10.1754 15.5584 10 15.1107 10C14.662 10 14.214 10.1754 13.8732 10.5252C13.8732 10.5252 7.13707 16.9326 6.49648 17.5881C5.85652 18.2439 5.81285 19.423 6.49648 20.1227C7.17949 20.8221 8.13362 20.8776 8.97182 20.1227L15.111 14.2446L21.2495 20.1227C22.0884 20.8776 23.0419 20.8221 23.7249 20.1227C24.4085 19.423 24.3652 18.2439 23.7246 17.5881Z' fill='%23444444'/%3E%3C/svg%3E%0A";

/* MOBILE
============================================= */
$mobile-portrait-search__alphabet-height: 45vh;
$mobile-portrait-search__regular-height: calc(#{$mobile-portrait-search__alphabet-height} + 50px);

$desktop-portrait-favorite__regular-height: 45vh;
$mobile-portrait-favorite__regular-height: calc(#{$desktop-portrait-favorite__regular-height} + (50px * 2));

$desktop-tour__height: 35vh;
$mobile-tour__height: 45vh;

/* Plant Gallery
============================================= */
$plant-gallery--height: 350px;

/* Phenology Chart - Table
============================================= */
$table-shadow--1: 60px;
$table-shadow--2: 30px;
$table-shadow--color-dark: #aaa;
$table-shadow--color-transparent: #fff;
