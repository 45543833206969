@import 'src/styles/css/variables';

$font-size__1: 16px;
$font-size__2: $font-size__1 + (1 * 4px);
$font-size__3: $font-size__1 + (2 * 4px);

$header: 100px;

$Modal--max-width: 675px;
$Modal--max-height: 600px;
$Modal__title--line-height: 1.25;
$Modal__content--padding: 16px;

$Modal--padding: 16px;

$ModalClose__button--line-height: 50px;
$ModalClose__button--margin-top: $Modal--padding;
$ModalClose--height: $ModalClose__button--line-height + $ModalClose__button--margin-top;

$container--padding: $Modal__content--padding * 0.75 $Modal__content--padding;
$container__background-color: #fff;
$container--border-radius: 5px;

#Modal {
  position: relative;
  max-width: $Modal--max-width;
  max-height: $Modal--max-height;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    //outline: none;
  }
}

#ModalHeader {
  display: flex;
  align-items: center;
  padding: $container--padding;
  background-color: $container__background-color;
  border-top-left-radius: $container--border-radius;
  border-top-right-radius: $container--border-radius;
  min-height: $modal-item--default-height;
  box-sizing: border-box;
  border-bottom: 1px solid $favorite-plants-border;

  &__title,
  &__sub-title {
    margin: 0;
  }

  &__title {
    font-size: $font-size__2;
    line-height: $font-size__2 * $Modal__title--line-height;
    font-weight: 400;
  }

  &__sub-title {
    font-size: $font-size__1;
    line-height: $font-size__1 * $Modal__title--line-height;
    font-weight: 300;
    color: transparentize(#000, 0.2);
  }

  &__action {
    margin-left: auto;
  }
}

#ModalMain {
  flex: 1;
  padding: $container--padding;
  overflow-y: auto;
  background-color: #f3f3f3;

  &[data-main-modal-padding-hide='true'] {
    padding: 0;
  }

  @media screen and (max-height: $Modal--max-height + $header + $ModalClose--height + (2 * $Modal--padding)) {
    max-height: calc(100vh - (#{$header} + #{$ModalClose--height} + (2 * #{$Modal--padding})));
  }
}

#ModalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 $Modal__content--padding;
  background-color: $container__background-color;
  border-bottom-left-radius: $container--border-radius;
  border-bottom-right-radius: $container--border-radius;

  #ModalFooterLeft,
  #ModalFooterRight {
    button {
      border: none;
      cursor: pointer;
      background-color: #fff;
      padding: 0.35rem 0.25rem;
      font-size: $font-size__1;
      color: $dark-grey;
      border-bottom: 1px solid #fff;

      &.green {
        color: $green;
        border-bottom: 1px dashed;
      }
    }
  }

  #ModalFooterRight {
    margin-left: auto;

    button {
      &:after {
        content: '>';
        padding-left: 0.5rem;
        color: $green;
      }
    }
  }
}

#ModalClose {
}

#ModalCloseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  background-color: #fff;
  border-radius: $ModalClose__button--line-height / 2;
  padding: 0;
  height: $ModalClose__button--line-height;
  width: $ModalClose__button--line-height;
  margin: $ModalClose__button--margin-top auto 0 auto;
}

.modal-clear__button {
  display: flex;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: #fff;
  height: $SearchPlant__button--length;
  line-height: $SearchPlant__button--length;

  .IconReset {
    color: $green;
    font-weight: bold;
    font-size: $modal-button__font-size;
  }
}
