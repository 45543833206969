@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700&display=swap');
@import './variables.scss';

@font-face {
  font-family: $nybg-gothic__font-family;
  src: url('../../fonts/ny_botanical_gothic-extrabold-webfont.woff');
}

@font-face {
  font-family: $nybg-gt__font-family;
  src: url('../../fonts/gt-super-text-regular-webfont.woff');
}
