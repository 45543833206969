@import 'styles/css/fonts';
@import './styles/css/variables.scss';
@import './styles/css/_mixin';

body {
  margin: 0;
  font-family: $default__font-family__normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  overflow: hidden;
  position: fixed;
  width: 100vw;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}

button {
  font-family: $default__font-family__normal;
}

.hide {
  display: none;
}

.show {
  display: flex;
}

.plants-container {
  display: flex;
  width: 100%;
  height: -webkit-fill-available;
  font-family: $default__font-family__normal;

  .plants-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    // TODO remove border in later project phase
    background-color: $white;

    .plant-names-wrapper {
      margin: 0rem 1rem 0 1rem;

      h4 {
        color: $green;
        padding: 0 1rem;
      }

      ul {
        height: 12rem;
        color: $dark-grey;
        font-size: 1rem;
        letter-spacing: 0;
        padding: 0 1rem;
        overflow-y: scroll;

        a {
          color: $dark-grey;
          text-decoration: none;
        }

        li {
          list-style-type: none;
          text-transform: capitalize;
          margin: 0.75rem 0;
        }
      }
    }
  }
}

.chevron.up::before {
  transform: rotate(-45deg);
}

.chevron.down::before {
  transform: rotate(135deg);
}

.chevron.left::before {
  transform: rotate(225deg);
}

.chevron::before {
  border-style: solid;
  border-width: 0.1rem 0.1rem 0 0;
  content: '';
  display: inline-block;
  height: 0.45rem;
  position: relative;
  width: 0.45rem;
  color: grey;
}

.sub-header {
  display: flex;
  border-bottom: 1px solid $favorite-plants-border;
  padding: 1.5rem 1rem 1.5rem 1rem;
  justify-content: space-between;

  span {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 20px;
  }
}

.loader-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 10rem;

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: $loader-height-width;
    height: $loader-height-width;
    margin-left: calc(#{$loader-height-width} / 2 * -1);
    margin-top: calc(#{$loader-height-width} / 2 * -1);
    border-color: #5e5e5e rgba(226, 218, 218, 0.1) rgba(226, 218, 218, 0.1);
    border-width: 5px;
    border-style: solid;
    border-radius: 50%;

    animation: spinner 0.5s infinite linear;

    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(90deg);
      }
      50% {
        transform: rotate(180deg);
      }
      75% {
        transform: rotate(270deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@media (hover: none) {
  *:focus {
    outline: none;
  }
}

body {
  @media (hover: none) {
    .hide-on-mobile {
      display: none;
    }
  }

  @media (hover: hover) {
    .hide-on-desktop {
      display: none;
    }
  }
}

// Prevent mobile device Zoom
body,
#SearchPlantsCardHeader,
.PlantPreview__details,
.esri-popup__main-container .esri-popup__content,
.map-layers,
.FavoriteMain,
.TourMain,
.TourFooter,
#ModalHeader,
#ModalMain .TourMain .options-container,
#FilterModalContent .filter-container,
#FilterModalContent #SearchPlantRecordsFilter,
#SearchPlantNav,
.SpecificPlantTopDetails,
.ReactVirtualized__Grid,
.plant-container .header,
.plant-container .description,
.plant-container .grid.section-two,
.plant-container .index-buttons-wrapper {
  @include mobile-zoom-scalable-no-y;
}

// Prevent mobile device Zoom
#SearchPlantAlphabet {
  @include mobile-zoom-scalable-no-x;
}
