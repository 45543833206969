@import '../../styles/css/variables';
@import '../../styles/css/mixin';

$home-main-content__margin: 2rem;
$home-main-content__margin--small-screen: 1.25rem;
$device-height__break-point: 680px;

#Home {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/nybg-home-background__2021.jpeg');
  height: calc(100% - #{$header__height});

  .page-main {
    display: flex;
    align-items: center;
    height: 100%;
    overflow: auto;
    position: relative;
    margin: 0;
    max-width: none;
  }

  .page-main__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    background-color: rgba(#000, 0.5);

    @media (min-width: $device-width__break-point) {
      max-width: 45vw;
      position: absolute;
      left: 0;
      top: 0;
      min-height: calc(100% - (2 * #{$home-main-content__margin}));
      margin: $home-main-content__margin;
      padding: $home-main-content__margin 5%;
      box-sizing: border-box;

      .page-main__title {
        line-height: 2;
        @include nybg-title-font;
      }

      .home__description {
        line-height: 2;
        font-size: 22px;
      }
    }

    // Adjust page content for screens with a small height
    @media (max-height: $device-height__break-point) {
      max-width: 60vw;
      margin: $home-main-content__margin--small-screen;

      .page-main__title,
      .home__description {
        line-height: 1.8;
      }
    }
  }

  .home__description,
  .page-main__title {
    color: #fff;
  }

  .page-main__title {
    font-size: $font-size-5;
    font-weight: 600;
  }

  .home__description {
    line-height: 1.5;
    font-size: $font-size-1;
  }
}

#HomeFilterLayer {
  height: 100%;
}

.home__buttons {
  #LaunchPlantTracker {
    cursor: pointer;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: $font-size-3;
    margin: 1rem;
    margin-left: 0;
    padding: 0;
    background-color: $green;
    height: 60px;
    line-height: 60px;
    white-space: nowrap;

    @media (max-width: $mobile-device) {
      display: block;
      margin: 1rem auto;
    }
  }

  .LaunchPlantTracker__link {
    display: block;
    color: #fff;
    padding: 0 1.125rem;
    text-decoration: none;
  }
}

@media (min-width: $device-width__break-point + 1px) {
  #Home {
    [data-content-right-aligned='true'] {
      margin-left: auto;
    }

    .home__description {
      margin: 2rem 0;
    }

    #HomeFilterLayer {
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.35) 55%,
        rgba(0, 0, 0, 0.15) 75%,
        rgba(0, 0, 0, 0.65) 100%
      );
    }
  }
}

@media (max-width: $device-width__break-point) {
  #Home {
    background-color: #000;

    @media (orientation: portrait) {
      background-position: top center;
      background-image: url('../../images/nybg-home-background-portrait__2021.jpg');
      background-size: contain;
    }

    .page-main__content {
      margin: 0 auto;
      padding: 1.25rem 1.5rem;
      max-width: 100%;
    }
  }

  #HomeFilterLayer {
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.85) 0%,
      rgba(0, 0, 0, 0.35) 55%,
      rgba(0, 0, 0, 0.15) 75%,
      rgba(0, 0, 0, 0.65) 100%
    );
  }
}

@media (max-width: $device-width__break-point) {
  #Home {
    .page-main {
      align-items: flex-end;
    }

    .home__description {
      margin: 1.25rem 0;
    }
  }
}
