@import '../../../styles/css/variables';

.find-others-plants-like-this__link {
  margin: 1rem;
  align-self: center;
  color: $green;
  font-family: $default__font-family__normal;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: underline;
}
