@import '../../../../styles/css/variables';

#PlantSearchResultsNoResults {
  padding: 1rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  @media screen and (min-width: $tablet-device) {
    height: 100%;
  }
}

.PlantSearchResultsNoResults__title,
.PlantSearchResultsNoResults__sub-title {
  color: red;
}

.PlantSearchResultsNoResults__title {
  margin: 0;
  font-size: 2.25rem;
  line-height: 2.25rem;

  @media screen and (min-width: $tablet-device) {
    font-size: 2.75rem;
    line-height: 2.75rem;
  }
}

.PlantSearchResultsNoResults__sub-title {
  margin: 0.25rem 0;
  font-size: 1.25rem;
}
