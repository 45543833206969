.ada-layers-disabled {
  position: absolute;
  display: flex;
  background: rgb(0 0 0 / 50%);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  justify-content: center;
  align-items: flex-start;
}

.ada-layers-disabled__label {
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 86px;
}
