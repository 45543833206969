@import './src/styles/css/variables';

#SearchPlantRecordsFilter {
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;

  .all-living-records {
    display: flex;
    align-items: center;
    padding-left: 1rem;

    .all-records {
      padding-right: 1rem;
    }

    span {
      white-space: nowrap;
    }
  }
}

#SearchPlantTop #SearchPlantRecordsFilter {
  @media (hover: none) {
    display: none;
  }
}

#FilterModalContent #SearchPlantRecordsFilter {
  min-height: $SearchPlant__search-bar--height;
  border-top: 1px solid $favorite-plants-border;

  @media (hover: hover) {
    display: none;
  }
}
