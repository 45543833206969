@import 'src/styles/css/mixin';

.commonname {
  @include plant-search-results__common;

  a {
    color: #000000;
    text-decoration: underline;
  }

  .common-names__label {
    text-decoration: none;
  }
}
