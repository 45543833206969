@import 'src/styles/css/variables';

.CollectionsLanguageModal__description {
  margin: 0;
  padding: 1.5rem;
  line-height: 1.5rem;
  background-color: #fff;

  a {
    color: $green;
  }
}
