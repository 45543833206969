@import './variables.scss';
@import './mixin';

$padding-right: 10px;
$tour-button__width: 140px;
$tour-div__width: 280px;
$prev-next-button__length: 25px;

div.tour-button {
  width: $tour-div__width;
}

.tour-button {
  @include esri-button;
  width: $tour-button__width;
  font-size: $font-size-2;
  justify-content: flex-start;
  padding-right: $padding-right;
  box-sizing: border-box;

  .close-tour {
    @include button;
    border-radius: 50%;
    min-width: $esri--button__length;
    min-height: $esri--button__length;
  }

  .stops-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $tour-div__width - ($esri--button__length + $padding-right);

    .active-stop {
      width: $tour-div__width - ($esri--button__length + $padding-right + (2 * $prev-next-button__length));
      @include scientific-name-text-styling;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      background-color: $white;
      border: none;
      padding: 0;

      span {
        width: 2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background-color: $white;
        border: none;
      }
    }

    .next-stop,
    .previous-stop {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      padding: 0;
      min-width: $prev-next-button__length;
      min-height: $prev-next-button__length;

      &.hidden {
        visibility: hidden;
      }
    }
  }
}

// [data-search-plants-minimized='false'] .tour-button {
//   bottom: $SearchPlant__container--height + $map__default-margin;
// }

.tour-options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  max-height: $desktop-tour__height;

  @media (hover: none) {
    max-height: $mobile-tour__height;
  }

  .header-section,
  .options-container {
    padding: 1.25rem;
  }

  .header-section {
    background-color: $white;

    .title {
      margin: 1rem 0;
      font-size: 20px;
    }

    .subtitle {
      opacity: 0.8;
      color: $black;
      font-weight: 300;
    }
  }

  .options-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 1rem;
    height: 100vh;
    max-height: $desktop-tour__height;

    @media (hover: none) {
      max-height: $mobile-tour__height;
    }

    .option {
      display: flex;
      flex-wrap: wrap;

      .description-wrapper {
        height: auto;
        font-size: 14px;
        margin: 0 30px 1rem 30px;

        button {
          padding-left: 0.5rem;
          color: #008850;
          font-size: 14px;
          border: none;
          background-color: $tours-options-grey;

          .arrow::before {
            content: '\2192';
          }
        }

        .description,
        .duration {
          margin: 0 auto;
        }

        .description {
          color: #696a6a;

          .tour-title {
            height: 1rem;
            color: $green;
            font-size: 12px;
            font-weight: bold;
            margin: 0.5rem 0;
          }

          .tour-stops {
            li {
              margin: 0.5rem 0rem;

              .scientific-name {
                font-weight: 600;
                color: black;
              }

              .common-name {
                font-style: italic;
              }
            }
          }
        }

        .duration {
          font-size: 12px;
        }
      }
    }
  }
}

.tour-buttons-container {
  display: flex;
  height: $SearchPlant__search-bar--height;
  background-color: $white;
  width: -webkit-fill-available;
  justify-content: flex-end;
  padding: 0 1rem;

  .create-custom,
  .start-tour {
    font-size: 0.85rem;
    border: none;
    background-color: $white;
  }

  .create-custom {
    color: $green;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-position: under;
  }

  .start-tour {
    cursor: pointer;
    color: $dark-grey;

    &.faded {
      opacity: 0.5;
    }
  }

  .start-tour > .chevron::before {
    color: $color--light-green;
    transform: rotate(45deg);
  }
}

.tour-stops-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  border-radius: 5px;
  background-color: $white;
  height: $desktop-tour__height;

  @media (hover: none) {
    height: $mobile-tour__height;
  }

  .tour-stop {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: auto;
    padding: 0.5rem;
    border-bottom: 1px solid $favorite-plants-border;

    &.go-back {
      max-height: 3.5rem;
    }

    &.loading-border {
      border-top: 1px solid $favorite-plants-border;
    }

    p {
      margin: 0.6rem 0;
    }

    .tour-text {
      display: flex;
      align-items: center;

      .WidgetImage__container {
        margin-right: 0.25rem;
      }

      .title-wrapper {
        padding: 0 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .title,
      .subtitle {
        margin: 0;
      }

      .title {
        font-size: 15px;
        font-weight: 600;
        font-style: italic;

        a {
          text-decoration: none;
          color: $black;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      .subtitle {
        color: #666666;
        font-size: 14px;
        line-height: 1.25;
      }
    }

    .tour-widgets {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin-left: auto;

      button {
        border: none;
        background-color: $white;
      }

      .see-on-map,
      .share-tour {
        display: flex;
        border: none;
        padding: 0;
        background-color: transparent;
      }

      .see-on-map {
        cursor: pointer;
        align-items: center;

        .green {
          color: $color--light-green;
          font-size: 16px;
        }
      }

      .share-tour {
        justify-content: flex-end;

        svg {
          cursor: pointer;
        }
      }
    }
  }

  .back-button {
    display: flex;
    background-color: $white;
    font-size: 0.85rem;
    border: none;

    svg {
      margin-top: auto;
      margin-bottom: auto;
    }

    span {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .plant__read-more {
    margin-top: 0.35rem;
    color: $color--light-green;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
  }
}

@media screen and (max-width: $mobile-device) {
  .tour-button {
    &.hide-on-mobile {
      display: none;
    }
  }
}

.tour-stop__go-back--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $SearchPlant__search-bar--height;
  border-top: 1px solid $favorite-plants-border;
  box-sizing: border-box;

  .back-button {
    color: $dark-grey;
    border: none;
    display: flex;
    background-color: #fff;
    font-size: $modal-button__font-size;
  }
}

.back-button {
  padding: 0;
}

[data-selected-plant='true'] {
  background-color: $selected-item__background;
}
