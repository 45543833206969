@import '../../../styles/css/variables';

.SpecificPlantTopDetails {
  padding-bottom: 0.5rem;

  .header {
    display: flex;
    align-items: center;
  }

  .main {
    display: flex;

    .main-details {
      flex: 1;
    }
  }

  .WidgetImage__container {
    @media (min-width: $device-width__break-point) {
      display: none;
    }
  }

  .WidgetImage {
    align-self: flex-start;
  }
}
