@import '../../../styles/css/variables.scss';

$stop-number__length: 19px;

.TourStopNumber {
  display: inline-flex;
  align-items: center;

  .stop-number {
    display: inline-block;
    height: $stop-number__length;
    width: $stop-number__length;
    line-height: $stop-number__length;
    color: #fff;
    font-family: $default__font-family__normal;
    font-weight: 600;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    background-color: black;
    border-radius: 50%;
    margin: 0.25rem;
  }
}
