@import '../../../styles/css/variables';

#SearchPlantTop {
  .SearchPlantInput__container {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0.25rem 0.125rem;
  }

  .SearchPlantInput__field-container {
    margin: auto 0;
  }

  .SearchPlantNav__button {
    padding: 0;
  }

  .SearchPlantInput__field {
    border-bottom: 1px solid #ccc;
    font-family: $default__font-family__normal;
  }

  .SearchPlantInput__field-container,
  #AdvancedSearchFields {
    font-size: 14px;
  }

  @media (max-width: $mobile-device) {
    .SearchPlantInput__field,
    .MuiInput-formControl {
      font-size: 13px;
    }
  }
}

.SearchPlantsAdvancedFilters {
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-wrap: wrap;

  .SearchPlantInput__field-container {
    display: flex;
    flex: 1;
    margin: 0.25rem 0.5rem 0.25rem 0;
    height: $SearchPlant__button--length;
    background-color: #fff;

    &:not(.alphabet__search-message) {
      border-radius: $SearchPlant__button--length / 2;
    }

    .SearchPlantInput__field {
      width: 100%;
      padding: 0 0 0 0.5rem;
      font-size: 1rem;
      border-bottom: none;
      background-color: transparent;
    }

    .SearchPlantNav__button {
      padding: 0 0.25rem 0 0;
      background-color: transparent;
    }
  }

  .SearchPlantNav__select-letter {
    display: flex;
    width: 100%;
    color: $dark-grey;
    padding: 0 0.5rem;
    line-height: $SearchPlant__button--length;
    font-weight: 600;
    white-space: nowrap;
  }
}
