@import '../../styles/css/variables';

#PlantDataLegend {
  padding: 0 0 0 1rem;
  border-bottom: 1px solid $favorite-plants-border;
}

.PlantDataLegend__item {
  display: flex;
}

.PlantDataLegend__item-label {
  padding-left: 0.25rem;
}
