@import '../../styles/css/variables';

.esri-popup__main-container {
  background-color: #fff;

  @media (hover: hover) {
    max-width: 340px;
  }

  .esri-popup__navigation {
    background-color: transparent;
    margin: 0 6px 0 auto;
  }

  .esri-popup__header {
    .esri-popup__header-title {
      color: $color--light-green;
      font-size: $font-size-1;
      font-style: italic;
      font-family: $default__font-family__normal;
      font-weight: bold;
      background-color: transparent;
    }

    .esri-popup__header-buttons {
      align-items: center;

      .esri-popup__button:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 6px 0 6px 6px;
        padding: 0;
        width: 22px;
        height: 22px;
        background-color: #999999;
        border-radius: calc(22px / 2);
        color: #fff;
        font-weight: bold;

        .esri-icon-close {
          color: #fff;
          width: 14px;
          height: 14px;
          font-size: 14px;
          flex: 0 0 14px;
          font-weight: bolder;
        }
      }
    }
  }

  .esri-feature__main-container {
    padding-top: 0.25rem;

    p {
      margin: 0 0 0.55em 0;

      strong {
        font-weight: 600;
      }
    }
  }

  .esri-popup__footer {
    position: relative;
    flex-direction: column;

    .esri-popup__inline-actions-container {
      display: flex;
      justify-content: center;
      width: 100%;
      position: initial;

      .esri-popup__action-text {
        overflow: initial;
      }

      [title='Get Details'] {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $SearchPlant__button--length;
        width: 175px;
        max-width: 175px;
        color: #fff;
        background-color: #000;
        margin-bottom: 0.5rem;

        .esri-popup__icon {
          display: none;
        }

        .esri-popup__action-text {
          color: #fff;
          font-weight: 700;
          display: block;
        }
      }

      &:only-child {
        flex-direction: column;

        .esri-popup__action[title='Zoom to'] {
          margin-right: auto;
        }
      }

      &:not(:only-child) {
        .esri-popup__action[title='Zoom to'] {
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}

#MapContainer {
  @media (hover: none) {
    .esri-ui {
      z-index: 1;

      .esri-popup__main-container {
        position: fixed;
      }

      .esri-popup__header-container--button {
        position: relative;

        .esri-popup__header-title:hover {
          background-color: transparent;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          margin: 6px 2px 6px 7px;
          font-size: 20px;
          font-weight: bold;
          padding: 0 1rem;
          background-image: url($arrow-up);
          background-repeat: no-repeat;
        }

        &[aria-label='Collapse']:after {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.esri-popup--feature-menu-open {
  [title='Get Details'] {
    display: none !important;
  }
}
