@import './variables.scss';
@import './mixin.scss';

.share-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
  height: 4.75rem;
  width: 20rem;
  border-radius: 50px;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 2rem;

  p {
    color: $dark-dark-grey;
    font-size: 0.85rem;
    margin: 0 auto;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;

    button {
      cursor: pointer;
      border: none;
      background-color: #ffffff;
      padding: 0;
      margin: 0 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .x-icon {
      height: 33px;
      width: 33px;
      /** * NOTE:
        keeping this to pixels so the fixed
        height/width matches Manny's SVG
        dimensions (Twitter icon came from
        official twitter brand website)
      */
    }
  }
}

.share-button-container {
  @include button;
  width: $SearchPlant__button--length;
  height: $SearchPlant__button--length;
}
