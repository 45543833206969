.WidgetImage__container {
  position: relative;

  .PlantGalleryModal__button {
    background-color: transparent;
    padding: 0.25rem 0.5rem;
    border: none;
    right: 0;
    bottom: 0;
    position: absolute;
    cursor: pointer;
  }

  .WidgetImage {
    border: 1px solid #ccc;
    background-color: #fff;
  }
}

.WidgetImage__placeholder {
  height: 100%;
  padding: 5px;
  display: flex;
  text-align: center;
  align-items: center;
  box-sizing: border-box;
}
