@import './src/styles/css/variables';
@import './src/styles/css/mixin';

.page-main {
  @include default-container-width;

  .page-main__title,
  .page-main__sub-title {
    font-size: 1.25em;
  }

  .page-main__title {
    margin: 0;
    font-weight: 400;
    font-size: 1.75em;
  }

  .page-main__sub-title {
    h3 {
      margin: 0;
      font-weight: 700;
      font-size: 1.3em;
    }
  }

  p {
    margin-top: 1rem;
    line-height: 1.4;
  }
}
