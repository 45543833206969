@import 'src/styles/css/mixin';

.genus_common_name_primary {
  @include plant-search-results__common;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;

  a {
    color: #000000;
    text-decoration: underline;
  }

  .common-names__label {
    text-decoration: none;
  }
}
