@import '../../../styles/css/variables.scss';

.specific-favorite__wrapper {
  position: relative;
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid $favorite-plants-border;
  background-color: #fff;

  .favorite-remove-button__confirm-wrapper {
    background-color: $color--red-danger;
    display: flex;

    .favorite-remove-button__confirm {
      border: none;
      color: #fff;
      font-size: 1.5rem;
      margin: 0;
      padding: 0;
      background-color: transparent;
    }
  }

  .specific-favorite {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    transition: all 0.3s ease 0s;

    .plant-image {
      display: flex;
      height: auto;
      flex: 1.5 1 3.5rem;
      max-width: 3.5rem;
    }

    .specific-favorite-content {
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      display: flex;
      align-items: center;
      margin-top: auto;
      margin-bottom: auto;
      min-height: $SearchPlant__button--length * 2;
      width: -webkit-fill-available;

      .names {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin: auto 0.25rem auto 0.75rem;
        flex: 1.25 1 9.375rem;

        h4,
        p {
          margin: 0 auto 0 0;
        }

        h4 {
          font-weight: 600;
        }

        p {
          color: #666666;
          font-size: 12px;
          font-style: italic;
        }
      }

      .learn-more {
        margin-top: 0.5rem;
        color: $color--light-green;
        font-size: 12px;
        text-decoration: none;
      }
    }
  }

  .favorite-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $SearchPlant__button--length;
      height: $SearchPlant__button--length;
      padding: 0;
    }
  }
}
