@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import '../../../styles/css/variables';

#ModalMain {
  .SpecificPlantGallery {
    padding: 0;
    min-width: 100%;
    max-width: 100%;
    background-color: #000;

    .image-gallery {
      height: 100%;
    }
  }
}

#SpecificPlantTop {
  .SpecificPlantGallery {
    padding: 0 0 1rem 0;

    .image-gallery {
      height: 100%;
    }
  }
}

.SpecificPlantGallery {
  padding: 2rem 1rem 0 0;

  @media (min-width: $device-width__break-point) and (hover: hover) {
    min-width: 100% * (1/3);
    max-width: 100% * (1/3);
  }

  @media (max-width: $device-width__break-point) {
    //display: none;
  }
}

.SpecificPlantGallery__image {
  width: 100%;
}

.SpecificPlantGallery__link-container {
  padding: 0.25rem 0.5rem;
  text-align: right;
}

.SpecificPlantGallery__link {
  color: $green;
  font-size: 14px;
  font-style: italic;
  text-decoration: underline;
}

.image-gallery {
  width: 100%;
  height: $plant-gallery--height;

  .image-gallery-content {
    .image-gallery-slide-wrapper {
      padding: 10px;
      box-sizing: border-box;
      background-color: #000;

      .image-gallery-left-nav .image-gallery-svg,
      .image-gallery-right-nav .image-gallery-svg {
        width: 30px;
        height: 60px;
      }

      .image-gallery-left-nav,
      .image-gallery-right-nav {
        padding: 10px;
      }

      .image-gallery-slide img {
        width: 100%;
        height: $plant-gallery--height;
      }
    }

    .image-gallery-thumbnail .image-gallery-thumbnail-image {
      object-fit: cover;
      height: 62px;
    }
  }
}

.gallery-placeholder {
  background-color: #ccc;
  width: 100%;
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-placeholder__description {
  font-size: 1.75rem;
  font-weight: 700;
}
