#SearchPlantAlphabet.SearchPlantsAlphabet {
  margin: 0 0.5rem;
  padding: 0.125rem 0;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;

  .SearchPlantAlphabet__letter {
    margin: 0.125rem 0.25rem 0 0;
  }
}
