@import 'src/styles/css/variables';

.AdvancedFiltersOptions__container {
  display: flex;
  height: $SearchPlant__button--length;
  margin: 0.25rem 0;

  .MuiFormControl-root {
    display: flex;
    justify-content: center;
    height: $SearchPlant__button--length;
    border-radius: $SearchPlant__button--length / 2;
    background-color: $dark-dark-grey;
    box-sizing: border-box;
    min-width: 95px;

    .MuiInput-formControl {
      margin: 0;
    }

    .MuiNativeSelect-select {
      padding: 0 0 0 0.75rem;
    }

    .MuiNativeSelect-select,
    .MuiNativeSelect-icon {
      color: #fff;
    }
  }
}
