@import 'variables';

@mixin scientific-name-text-styling {
  color: $green;
  font-style: italic;
  font-size: 15px;
  font-weight: 600;
}

@mixin default-container-width {
  width: 100%;
  max-width: $content__max-width;
  margin: auto;
  padding: 1rem;
  box-sizing: border-box;
}

@mixin button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #fff;
  padding: 0;
}

@mixin esri-button {
  @include button;
  width: $esri--button__length;
  height: $esri--button__length;
  border-radius: $esri--button__length / 2;
  box-shadow: $esri--button__box-shadow;
  color: #000;
}

@mixin plant-search-results__links {
  font-size: 15px;
  color: #000;
}

@mixin plant-search-results__common {
  color: #000;
  font-size: 15px;
  font-weight: 600;
  margin: $SearchPlantItem__margin;
  letter-spacing: 0.5px;
  line-height: 1.5;
}

@mixin scrollbar-styling {
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 0.5rem;
  }
}

@mixin mobile-zoom-scalable-no-x {
  touch-action: pan-x;
}

@mixin mobile-zoom-scalable-no-y {
  touch-action: pan-y;
}

@mixin nybg-title-font {
  font-family: $nybg-title__font-family;
  text-transform: uppercase;
}
