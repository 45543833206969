@import 'src/styles/css/variables';

#SearchPlantsAdvancedModal__results-container {
  border-top: 2px solid $favorite-plants-border;

  #SearchPlantResults {
    padding: 0.5rem;
  }

  &[data-search-type-advanced='false'],
  &[data-search-type-advanced='false'] #SearchPlantResults {
    height: $mobile-portrait-search__regular-height;
  }

  &[data-search-type-advanced='true'],
  &[data-search-type-advanced='true'] #SearchPlantResults {
    height: $mobile-portrait-search__alphabet-height;
  }
}
