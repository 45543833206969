@import 'variables.scss';
@import 'mixin.scss';

.header-preview {
  display: flex;
  margin-top: 1rem;
  height: 3rem;
  flex-wrap: nowrap;
  flex-direction: row;

  a {
    margin-left: 0.5rem;
  }

  h4 {
    margin: 0;
    color: $dark-grey;
    font-size: 1.25rem;
    font-weight: 600;
  }
}

.plant-container__wrapper {
  display: flex;
  max-width: $content__max-width;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - #{$header__height});
}

.plant-container {
  padding: 0 10px;
  font-family: $default__font-family__normal;

  &.index {
    @include default-container-width;
  }

  .header {
    @media (hover: hover) {
      margin: 1rem 0;
    }

    #ViewOnMap {
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0 0.5rem 0 0;
      min-height: $SearchPlant__button--length;
      border-radius: $SearchPlant__button--length / 2;
      background-color: $green;
      color: $white;
      font-size: $font-size-1;
      margin-bottom: 0.25rem;
      cursor: pointer;

      .IconWhiteMap {
        padding: 0 0.5rem;
      }
    }

    #ViewOnMap__link {
      display: inline-flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0 0.5rem 0 0;
      min-height: $SearchPlant__button--length;
      border-radius: $SearchPlant__button--length / 2;
      background-color: $green;
      color: $white;
      font-size: $font-size-1;
      text-decoration: none;

      .IconWhiteMap {
        padding: 0 0.5rem;
      }
    }

    #BackToSearch {
      display: flex;
      align-items: center;
      color: $dark-grey;
      font-size: 1.25rem;
      margin: 0.5rem 0;
    }

    .BackToSearch__button {
      display: flex;
      align-items: center;
      color: #fff;
      height: $SearchPlant__button--length;
      border-radius: $SearchPlant__button--length / 2;
      background-color: $color--light-green;
      font-size: $font-size-1;
    }

    .BackToSearch__title {
      padding: 0.5rem;

      &:empty {
        display: none;
      }
    }

    .BackToSearch__button-element {
      @include button;
      width: $SearchPlant__button--length;
      height: $SearchPlant__button--length;
      border-radius: $SearchPlant__button--length / 2;
      background-color: transparent;
    }

    .favorite {
      border: none;
      background-color: $white;
      margin: 0 0.125rem;
    }
  }

  .subtitle {
    margin: 0 0 1rem 0;
    color: $dark-grey;
    font-size: 1.5rem;
    font-style: italic;
    font-weight: normal;
    font-family: $default__font-family__normal;

    button {
      border: none;
      background: $white;
    }

    a {
      text-decoration: none;
      color: $chevron-grey;
    }

    .name-wrapper {
      .chevron.preview::before {
        height: 12px;
        width: 12px;
        color: $chevron-grey;
        border-width: 0.2rem 0.2rem 0 0;
      }
    }
  }

  .description {
    color: $dark-grey;
    font-size: 0.875rem;
    letter-spacing: 0.03rem;
    line-height: 1.4rem;
    padding: 0 10px 0 0;
    margin: 10px 0;

    &.preview {
      max-height: $SearchPlant__preview-details--height-desktop;
      overflow-y: auto;

      @media (max-width: $tablet-device) and (hover: none) {
        max-height: $SearchPlant__preview-details--height-mobile;
      }
    }
  }

  .index-buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    .view-on-map {
      display: flex;
      width: 7.6rem;
      height: auto;
      padding: 0.5rem;
      justify-content: space-evenly;
      border: 1px solid $green;
      border-radius: 2px;
      background-color: $white;
      color: $green;
      font-weight: bold;
      margin-left: auto;
      margin-right: auto;
      font-size: 0.8125rem;

      img {
        height: 14px;
        width: 14px;
        margin-left: 0.25rem;
      }
    }
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .visit-page,
    .plant-page {
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      text-decoration: none;
      padding: 0.5rem;
      display: flex;
    }

    .plant-page {
      background-color: #000000;
    }

    .visit-page {
      background-color: $green;
    }
  }

  .PlantPreview__details {
    padding: $SearchPlant__spacing / 2;
    max-height: $SearchPlant__container--height - $SearchPlant__search-bar--mobile-height;
    box-sizing: border-box;

    .section-one {
      font-size: 0.85rem;
      line-height: 1.25;

      .plant-line {
        vertical-align: top;
        word-break: break-all;

        &.first {
          width: 55px;
        }
      }
    }
  }

  .grid {
    display: grid;
    grid-column-gap: 0.3rem;

    &.section-one {
      grid-template-columns: 3.25rem auto;
    }

    &.section-two {
      grid-template-columns: 6rem auto;
    }

    .plant-line {
      padding: 0.3rem 0;
      margin: 0;
      font-size: 0.85rem;

      &.button {
        border: none;
        background-color: $white;
        text-align: left;
      }
    }
  }

  .green-font {
    a {
      color: $green;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .accession-table {
    margin: 2rem 0;
    overflow-x: auto;

    table {
      height: auto;
      width: auto;
      min-width: $table-min-width;
      border-spacing: 0;

      thead {
        tr {
          height: 1.875rem;
          background-color: $black;
          color: $white;
          font-size: 0.875rem;
          font-weight: 500;
          letter-spacing: 0.38px;
          text-align: left;

          th {
            padding: 0.5rem;
          }
        }
      }

      tbody {
        tr {
          td {
            height: 3rem;
            width: auto;
            color: $black;
            font-family: $default__font-family__normal;
            font-size: 0.8125rem;
            letter-spacing: 0.35px;
            padding: 0.125rem 1rem;

            a {
              color: $black;
            }
          }
        }
      }

      tr.hidden,
      th.Key,
      td.Key {
        display: none;
      }

      .Surveyed {
        text-align: center;
      }
    }

    button {
      height: auto;
      width: -webkit-fill-available;
      min-width: $table-min-width;
      padding: 0.5rem 0;
      background-color: $color--light-green;
      border: none;
      color: $white;
      font-family: $default__font-family__normal;
      font-size: 0.875rem;
      letter-spacing: 0.38px;
    }
  }
}

@media screen and (min-width: $tablet-device) {
  .plant-container {
    .accession-table {
      table,
      button {
        max-width: 100%;
      }
    }
  }
}

.not-living-plant-description {
  font-family: $default__font-family__normal;
  color: #a65661;
}
